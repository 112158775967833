import topic from '../topic';
import { getNoCatch } from '@api/request';

export const mtrbAPI = {
  getMtrbs: topic.mtrbTopic + '/get_mtrbs',
  addMtrb: topic.mtrbTopic + '/add_mtrb',
  getMtrbById: topic.mtrbTopic + '/get_mtrb_by_id',
  editMtrb: topic.mtrbTopic + '/edit_mtrb',
  deleteMtrbByIds: topic.mtrbTopic + '/delete_mtrb_by_ids',
  getAllMtrbsV1: topic.mtrbTopic + '/get_all_mtrbs_v1',
  copyMtrb: topic.mtrbTopic + '/copy_mtrb',
  syncMtrbToMes: topic.mtrbTopic + '/sync_mtrb_to_mes',
  getRichErpMtrb: params => getNoCatch(`${topic.mtrbTopic}/getRichErpMtrb`, params),
  getModrMtrbById: params => getNoCatch(`${topic.mtrbTopic}/get_modr_mtrb_by_id`, params),
  get_mtrb_catnip: topic.mtrbTopic + '/get_mtrb_catnip',
  get_scon_mtrb_catnip: topic.mtrbTopic + '/get_scon_mtrb_catnip'
};
